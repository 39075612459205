<template>
	<div>
		<el-tree  highlight-current check-strictly ref="tree"
			:data="data" 
			:props="defaultProps"  
			:default-checked-keys="[checkedID]" 
			:node-key="treeNodeKey"  
			:auto-expand-parent="false" 
			:default-expand-all="true"
			:config="config"
			:show-checkbox="config.multiple"
			@node-click="handleNodeClick"></el-tree>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				data: [],
				defaultProps: {
					children: 'Children',
					label: 'Name'
				},
				checkedID: "",
				treeNodeKey: "Id"
			};
		},
		props: {
			config: {
				code: null,
				type: null,
				multiple:null
			},
		},
		mounted() {
			this.init(); //加载表格数据
		},
		methods: {
			init() {
				var _this = this;
				_this.$ajax.send("omsapi/organization/getLogTreeList", "get", {
					type: 0,
					id:0
				}, (data) => {
					if (data.Result != null) {
						this.data = data.Result;
					}
				});

			},
			handleNodeClick(data) {
				this.$emit("onSelectedRow", data);
			},
			getSelectedAll(){
				var data= {
					OrgList:this.$refs.tree.getCheckedNodes()
				};
				var result = this.handleData(data);
				return result;
			},
			handleData(data){
				var result = {};
				if(data){
					if(data.OrgList && data.OrgList.length>0){
						var list = [];
						data.OrgList.forEach((item,index)=>{
							var model={OrgId:item.Id,OrgCode:item.Code,OrgName:item.Name};
							list.push(model);
						});
						result.OrgList = list;
					}
				}
				return result;
			}
		}
	};
</script>
<style>
</style>